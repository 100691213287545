import React, { useState, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Grid, TableBody, Button, Box
} from '@mui/material';
import toast from 'react-hot-toast';
import { BackIcon, Return } from '../../../../assets/svgs';
import useSearchParamsState from '../../../helpers/ulrSearchParams';
import MainContent from '../../../customComponents/mainContent';
import { INBOUND_QUEUE_ORDERS_PRODUCTS } from '../../../../queries/orders';
import ProductsTableLoader from '../../../customComponents/loaders/productsTableLoader';
import ReturnRow from './returnRow';
import TablePagination from '../../../shared/tablePagination';
import SingleInboundsFilter from './singleInboundsFilter';
import {
  TitleGrid, TitleTextGridContainer, PrimaryTitle, SecondaryTitle, PaperWrapper, TableGrid, FooterWrapper, MenuButton, CustomCheckbox,
  TableComponent, TableColumnHeaders, TableHeader, FourthTitle, ThirdTitle
} from './singleInboundDetails.styles';
import InboundWalkImage from '../../../../assets/images/InboundWalk.png';
import ReturnMapProductDialog from './returnProductDialog';
import ConfirmDialog from '../../../shared/confirmDialog';
import { ADD_TO_INVENTORY } from '../../../../mutations/orders';
import SuccessDialog from '../../../shared/successDialog';

const headers = [
  { name: 'S/N', width: '70px' }, { name: 'Product Name', width: '250px' }, { name: 'Supplier', width: '250px' }, { name: 'Batch No.', width: '200px' },
  { name: 'QTY Received', width: '200px' }, { name: 'QTY to Inbound', width: '200px' }, { name: 'UOM', width: '150px' }, { name: 'Pack Size', width: '200px' },
  { name: 'Cost Price', width: '150px' }, { name: 'Expire Date', width: '200px' }, { name: 'Status', width: '200px' }, { name: 'Action', width: '120px' }
];

const SingleInboundDetails = () => {
  const navigate = useNavigate();
  const [pageCount, setPageCount] = useSearchParamsState('pc', '10');
  const [pageNumber, setPageNumber] = useSearchParamsState('pn', '1');
  const [search, setSearch] = useSearchParamsState('search', '');
  const [status, setStatus] = useSearchParamsState('status', '');
  const [searchProductName, setSearchProductName] = useSearchParamsState('search', '');
  const [stateRows, setStateRows] = useState([]);
  const [selected, setSelected] = useState([]);
  const [openReturnDialog, setOpenReturnDialog] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [confirmAddToInventory, setConfirmAddToInventory] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [queueOrderProductId, setQueueOrderProductId] = useState(null);
  const [successDialog, setSuccessDialog] = useState(false);

  const initialState = {
    option: ''
  };
  const statusEnumMap = {
    MATCHED: 'MATCHED',
    UNMATCHED: 'UNMATCHED',
    ALL: 'ALL',
  };

  const [state, setState] = useState(initialState);

  const { inboundQueueOrderId, orderId } = useParams();

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const newSelections = stateRows.map((product) => product.id);
      return setSelected(newSelections);
    }
    return setSelected([]);
  };

  const handleSelect = (_, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const renderCheckbox = () => (
    <CustomCheckbox
      size="small"
      checked={selected.length === stateRows.length}
      onChange={handleSelectAll}
      sx={{ color: '#78AADA' }}
    />
  );

  const returnHeaders = () => headers.map(({ name, width }) => (
    <TableHeader style={{ width, lineHeight: '40px' }} key={name}>
      {name}
    </TableHeader>
  ));

  const {
    option
  } = state;

  const handleSetFilter = () => {
    setState({
      ...state,
      filters: {
        option
      }
    });
  };

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setState((_state) => ({
      ..._state,
      [name]: value
    }));
  };

  const variables = {
    pageCount,
    pageNumber,
    ...(status && { matchStatus: statusEnumMap[status] }),
    ...(searchProductName.length >= 3 ? { productName: searchProductName } : { productName: '' }),
    inboundQueueOrderId
  };

  const {
    loading, error, data, refetch
  } = useQuery(INBOUND_QUEUE_ORDERS_PRODUCTS, {
    fetchPolicy: 'cache-and-network',
    variables
  });

  const [addToInventory, { loading: addToInventoryLoading }] = useMutation(ADD_TO_INVENTORY);
  useEffect(() => {
    if (confirmAddToInventory) {
      const request = {
        inboundQueueOrderId: Number(inboundQueueOrderId),
        ...(queueOrderProductId && { inboundQueueOrderProductId: Number(queueOrderProductId) })
      };
      addToInventory({
        variables: { ...request },
      }).then(() => {
        setQueueOrderProductId(null);
        setSuccessDialog(true);
      }).catch((err) => toast.error(err.message));
      setConfirmAddToInventory(false);
    }
  }, [confirmAddToInventory]);

  useEffect(() => {
    if (data && data?.businessInboundQueueOrderProducts) {
      const { businessInboundQueueOrderProducts } = data;
      setStateRows(businessInboundQueueOrderProducts);
    }
  }, [data]);

  if (error) return <div>{error.message}</div>;

  const returnModalHandler = () => {
    if (!selected.length) return toast.error('Select product(s) to return');
    const filteredArray = stateRows.filter((item) => selected.includes(item.id)).map((item) => ({
      ...item,
      quantityToReturn: 1
    }));
    setSelectedRows(filteredArray);
    setOpenReturnDialog(true);
  };

  const addToInventoryHandler = (item) => {
    if (item?.matchStatus === 'UNMATCHED') {
      setOpenConfirmDialog(true);
      setQueueOrderProductId(item.id);
    } else {
      const request = {
        inboundQueueOrderId: Number(inboundQueueOrderId),
        ...(item && { inboundQueueOrderProductId: Number(item.id) })
      };
      addToInventory({
        variables: { ...request },
      }).then(() => {
        setSuccessDialog(true);
      }).catch((err) => toast.error(err.message));
      setConfirmAddToInventory(false);
    }
  };

  return (
    <MainContent>
      <Grid container direction="column" style={{ padding: '30px' }}>
        <TitleGrid container item>
          <TitleTextGridContainer container item xs={12} onClick={() => navigate(-1)} style={{ cursor: 'pointer' }}>
            <Grid item>
              <MenuButton>
                <BackIcon />
              </MenuButton>
            </Grid>
            <Grid item>
              <PrimaryTitle variant="h5">Back</PrimaryTitle>
            </Grid>
          </TitleTextGridContainer>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <SecondaryTitle variant="h5">{orderId ? `Order ${orderId}` : 'xxxxx'}</SecondaryTitle>
            </Grid>
            <Grid item style={{ display: 'flex', gap: '.6rem' }}>
              <Button
                variant="outlined"
                style={{ cursor: 'pointer' }}
                onClick={returnModalHandler}
              >
                <Return style={{ fontSize: '1.3rem', marginRight: '.2rem' }} />
                Return
              </Button>
              <Button
                variant="contained"
                style={{ cursor: 'pointer' }}
                onClick={() => addToInventoryHandler()}
                disabled={addToInventoryLoading}
              >
                { addToInventoryLoading ? 'loading ...' : 'Add to Inventory' }
              </Button>
            </Grid>
          </Grid>
        </TitleGrid>
        <Box style={{ marginBottom: '1.5rem' }}>
          <PaperWrapper elevation={0}>
            <Box style={{ margin: '1.5rem', display: 'flex', gap: '1rem' }}>
              <Box marginBottom={4}>
                <img src={InboundWalkImage} alt="success" width={45} />
              </Box>
              <Grid container item>
                <Grid item>
                  <ThirdTitle variant="h5">Inbound Order Walkthrough</ThirdTitle>
                </Grid>
                <Grid item>
                  <FourthTitle variant="h5">
                    The product(s) on this table are undergoing background checks to
                    identify recently ordered products that correspond to items in your current
                    inventory. The statuses will be updated as &quot;matched&quot; when a match is found and
                    &quot;unmatched&quot; when there is no match, or proceed to map manually. Afterwards,
                    you can proceed to add the products to your inventory.
                  </FourthTitle>
                </Grid>
              </Grid>
            </Box>
          </PaperWrapper>
        </Box>
        <PaperWrapper elevation={0}>
          <Grid item container style={{ marginTop: '3rem' }}>
            <SingleInboundsFilter
              search={search}
              setSearch={setSearch}
              handleSetFilter={handleSetFilter}
              matchStatus={statusEnumMap[status]}
              handleFilterChange={handleFilterChange}
              inboundQueueOrderId={inboundQueueOrderId}
            />
          </Grid>
          {loading
            ? <ProductsTableLoader />
            : (
              <TableGrid item container>
                <TableComponent item container>
                  <TableColumnHeaders item container>
                    <TableHeader style={{ width: '70px', lineHeight: '40px' }}>
                      {renderCheckbox()}
                    </TableHeader>
                    {returnHeaders()}
                  </TableColumnHeaders>
                  <TableBody>
                    {data?.businessInboundQueueOrderProducts.map((_row, indx) => (
                      <ReturnRow
                        key={_row.id}
                        row={_row}
                        rowIndx={indx}
                        handleSelect={handleSelect}
                        selected={selected}
                        addToInventoryHandler={addToInventoryHandler}
                        refetch={refetch}
                      />
                    ))}
                  </TableBody>
                </TableComponent>
              </TableGrid>
            )}

          <FooterWrapper item container>
            {data?.businessInboundQueueOrderProductsTotalNumber > 0 && (
              <TablePagination
                total={data?.businessInboundQueueOrderProductsTotalNumber}
                pageCount={+pageCount}
                setPageCount={setPageCount}
                pageNumber={+pageNumber}
                setPageNumber={setPageNumber}
              />
            )}
          </FooterWrapper>
        </PaperWrapper>
      </Grid>
      <ConfirmDialog
        openDialog={openConfirmDialog}
        setOpenDialog={setOpenConfirmDialog}
        title="Unmatched Products Detected"
        desc="We noticed that you have some unmatched products in your inventory. Would you like to create this unmatched products as new items in your inventory"
        options={['Cancel', 'Proceed']}
        setStatus={setConfirmAddToInventory}
      />
      <SuccessDialog
        openDialog={successDialog}
        setOpenDialog={setSuccessDialog}
        title="Product(s) Added to Inventory!"
        desc="Your selected products have been added successfully to your inventory, but you can proceed to update your selling prices of individual products on this list."
        refetch={refetch}
        handleRoute={() => navigate('/newly-added-product')}
        options={['Ignore', 'Ok, Proceed']}
      />
      <ReturnMapProductDialog rows={selectedRows} setSelectedRows={setSelectedRows} openDialog={openReturnDialog} setOpenDialog={setOpenReturnDialog} setSelected={setSelected} />
    </MainContent>
  );
};

export default SingleInboundDetails;
